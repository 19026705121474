import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { throttle } from 'throttle-debounce';
import Icon from 'components/common/icon';
import LoadingLine from 'components/common/loading-line';
import Map, { Marker, Polygon } from 'components/common/map';
import Input from 'components/common/input';
import moment from 'moment';
import api from 'helpers/api';
import history from 'helpers/history';
import colors from 'helpers/colors';
import location from 'helpers/location';

import VehicleScanner from './vehicle-scanner';

const vehicleIcon = (type, status, batteryPercent = false) => {
  if (batteryPercent) {
    const batteryKey = batteryPercent > 0.83 ? 6 : (batteryPercent > 0.66 ? 5 : (batteryPercent > 0.5 ? 4 : (batteryPercent > 0.33 ? 3 : (batteryPercent > 0.16 ? 2 : 1))));
    return { url: `https://storage.googleapis.com/flamingo-static/images/admin/${type.toLowerCase()}-${status.toLowerCase()}-${batteryKey}.png?v=2`, size: new window.google.maps.Size(29, 29), scaledSize: new window.google.maps.Size(29, 29), anchor: new window.google.maps.Point(14, 14) };
  }
  return { url: `https://storage.googleapis.com/flamingo-static/images/admin/${type.toLowerCase()}-${status.toLowerCase()}.png?v=2`, size: new window.google.maps.Size(29, 29), scaledSize: new window.google.maps.Size(29, 29), anchor: new window.google.maps.Point(14, 14) };
};

const filterOptions = ['INUSE', 'AVAILABLE', 'UNAVAILABLE', 'MAINTENANCE', 'TRANSIT', 'DEMO', 'RESERVED', 'CHARGING', 'REPAIR'];

const batteryOptions = [
  { label: 'All Battery', value: '1.1' },
  { label: 'Below 95%', value: '0.95' },
  { label: 'Below 90%', value: '0.9' },
  { label: 'Below 85%', value: '0.85' },
  { label: 'Below 80%', value: '0.8' },
  { label: 'Below 75%', value: '0.75' },
  { label: 'Below 70%', value: '0.7' },
  { label: 'Below 65%', value: '0.65' },
  { label: 'Below 60%', value: '0.6' },
  { label: 'Below 55%', value: '0.55' },
  { label: 'Below 50%', value: '0.5' },
  { label: 'Below 45%', value: '0.45' },
  { label: 'Below 40%', value: '0.4' },
  { label: 'Below 35%', value: '0.35' },
  { label: 'Below 30%', value: '0.3' },
  { label: 'Below 25%', value: '0.25' },
  { label: 'Below 20%', value: '0.2' },
  { label: 'Below 15%', value: '0.15' },
  { label: 'Below 10%', value: '0.1' },
  { label: 'Below 5%', value: '0.05' },
];

const modelOptions = [
  { label: 'All Models', value: 'ALL' },
  { label: 'ES', value: 'SEGWAY_ES' },
  { label: 'MAX', value: 'SEGWAY_MAX' },
  { label: 'MAX PRO', value: 'SEGWAY_MAX_PRO' },
  { label: 'MAX PLUS', value: 'SEGWAY_MAX_PLUS' },
  { label: 'MAX PLUS X', value: 'SEGWAY_MAX_PLUS_X' },
  { label: 'S90L', value: 'SEGWAY_S90L' },
  { label: 'A200', value: 'SEGWAY_A200' },
];

class VehicleFinder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scannerActive: false,
      searchActive: false,
      addressSearch: false,
      filtersActive: false,
      query: '',
      results: [],
      addressResults: [],
      mapVehicles: [],
      hubs: [],
      zones: [],
      regions: [],
      filters: { battery: '1.1', statuses: [], model: 'ALL' },
    };

    this.map = React.createRef();
    this.searchInput = React.createRef();
    this.autoComplete = new window.google.maps.places.AutocompleteService();
    this.autoCompleteSessionToken = new window.google.maps.places.AutocompleteSessionToken();
    this.handleMapBoundsChange = throttle(500, this.handleMapBoundsChange.bind(this));

    this.handleLocate = this.handleLocate.bind(this);
    this.handleLocation = this.handleLocation.bind(this);

    this.loadHubs = this.loadHubs.bind(this);
    this.loadZones = this.loadZones.bind(this);
    this.loadRegions = this.loadRegions.bind(this);
    this.loadMapForBounds = this.loadMapForBounds.bind(this);
    this.batteryFilter = this.batteryFilter.bind(this);
    this.modelFilter = this.modelFilter.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
    this.loadFilters = this.loadFilters.bind(this);
    this.toggleStatus = this.toggleStatus.bind(this);
    this.toggleScanner = this.toggleScanner.bind(this);
    this.handleSearchFocus = this.handleSearchFocus.bind(this);
    this.handleSearchBlur = this.handleSearchBlur.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handlePredictions = this.handlePredictions.bind(this);
    this.search = throttle(500, this.search);
    this.renderResult = this.renderResult.bind(this);
    this.renderAddress = this.renderAddress.bind(this);
    this.onVehicleClick = this.onVehicleClick.bind(this);
    this.onPlaceClick = this.onPlaceClick.bind(this);
    this.renderOption = this.renderOption.bind(this);
  }

  componentDidMount() {
    document.title = 'Vehicles | Flamingo Admin';
    this.loadFilters();
    this.loadHubs();
    this.loadZones();
    this.loadRegions();
  }

  /* ------ Map ------ */

  handleMapBoundsChange(ne, sw, zoom) {
    return this.loadMapForBounds(ne.lat(), ne.lng(), sw.lat(), sw.lng(), zoom, this.state.filters);
  }

  loadMapForBounds(neLatitude, neLongitude, swLatitude, swLongitude, zoom, filters) {
    let endpoint = `/vehicle/area?neLatitude=${neLatitude}&neLongitude=${neLongitude}&swLatitude=${swLatitude}&swLongitude=${swLongitude}&zoom=${zoom}`;

    if (filters.battery && filters.battery !== '1.1') {
      endpoint += '&battery=' + filters.battery;
    }

    if (filters.model && filters.model !== 'ALL') {
      endpoint += '&model=' + filters.model;
    }

    if (filters.statuses && filters.statuses.length > 0) {
      endpoint += filters.statuses.map((status) => `&status[]=${status}`).join('');
    }

    const currentBounds = { neLatitude, neLongitude, swLatitude, swLongitude, zoom };
    return api.get(endpoint)
      .then((res) => this.setState({ mapVehicles: res.data.data, currentBounds }))
      .catch(console.log);
  }

  handleLocate() {
    return location.getLocation(true)
      .then(this.handleLocation)
      .catch(console.log);
  }

  handleLocation(location) {
    this.map.current.setCentre(new window.google.maps.LatLng(location.latitude, location.longitude));
  }

  /* ------ API ------ */

  loadHubs() {
    return api.get('/hub')
      .then((res) => this.setState({ hubs: res.data.data }))
      .catch(console.log);
  }

  loadZones() {
    return api.get(`/zone/area?active=true&date=${encodeURIComponent(moment().format())}`)
      .then((res) => this.setState({ zones: res.data.data }))
      .catch(console.log);
  }

  loadRegions() {
    return api.get('/region/service-area')
      .then((res) => this.setState({ regions: res.data.data }))
      .catch(console.log);
  }

  handleApiError(error) {

  }

  /* ------ Filters ------ */

  loadFilters() {
    const filtersString = localStorage.getItem('vehicleFilters');
    if (filtersString) {
      const filters = JSON.parse(filtersString);
      this.setState({ filters });
    }
  }

  toggleFilters() {
    if (this.state.searchActive) {
      this.searchInput.current.input.current.focus();
      const addressSearch = !this.state.addressSearch;
      this.setState({ addressSearch }, () => this.search(this.state.query));
    } else {
      const filtersActive = !this.state.filtersActive;
      this.setState({ filtersActive, searchActive: false, scannerActive: false });
    }
  }

  toggleStatus(status) {
    const filters = this.state.filters;

    const statusIndex = filters.statuses.indexOf(status);
    if (statusIndex > -1) {
      filters.statuses.splice(statusIndex, 1);
    } else {
      filters.statuses.push(status);
    }

    this.setState({ filters }, () => this.filterChange(filters));
    localStorage.setItem('vehicleFilters', JSON.stringify(filters));
  }

  batteryFilter(battery) {
    const filters = this.state.filters;
    filters.battery = battery;

    this.setState({ filters }, () => this.filterChange(filters));
    localStorage.setItem('vehicleFilters', JSON.stringify(filters));
  }

  modelFilter(model) {
    const filters = this.state.filters;
    filters.model = model;

    this.setState({ filters }, () => this.filterChange(filters));
    localStorage.setItem('vehicleFilters', JSON.stringify(filters));
  }

  filterChange(filters) {
    const cb = this.state.currentBounds;
    if (cb) {
      this.loadMapForBounds(cb.neLatitude, cb.neLongitude, cb.swLatitude, cb.swLongitude, cb.zoom, filters);
    }
  }

  /* ------ QR Scanner ------ */

  toggleScanner() {
    const scannerActive = this.state.searchActive ? false : !this.state.scannerActive;
    this.setState({ scannerActive, searchActive: false, addressSearch: false, filtersActive: false, query: '', results: [], addressResults: [] });
  }

  /* ------ Search ------ */

  handleSearchFocus() {
    this.setState({ searchActive: true, filtersActive: false, scannerActive: false }, () => setTimeout(() => window.scrollTo(0, 0), 400));
  }

  handleSearchBlur() {
    // this.setState({ searchActive: this.state.query.length > 0, addressSearch: false });
  }

  handleSearchChange(query) {
    this.setState({ searching: true, searchActive: true, query }, () => {
      this.search(query);
    });
  }

  search(query) {
    if (query.length < 2) {
      this.setState({ searching: false, results: [], addressResults: [] });
      return;
    }
    if (this.state.addressSearch) {
      const placeData = { input: query, sessionToken: this.autoCompleteSessionToken, componentRestrictions: { country: 'nz' } };
      return this.autoComplete.getPlacePredictions(placeData, this.handlePredictions);
    }
    return api.post('/vehicle/search', { query, limit: 10 })
      .then((res) => this.setState({ results: res.data.data, searching: false }))
      .catch(this.handleApiError);
  }

  handlePredictions(predictions) {
    if (predictions) {
      this.setState({ addressResults: predictions, searching: false });
    }
  }

  /* ------ Handlers ------ */

  onVehicleClick(e, vehicle) {
    if (e.metaKey || e.shiftKey) {
      return window.open(`/vehicle/${vehicle.id}`);
    }
    history.push({ pathname: `/vehicle/${vehicle.id}`, state: { vehicle } });
  }

  onPlaceClick(place) {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId: place.place_id }, (results) => this.map.current.setCentre(results[0].geometry.location));
    this.setState({ scannerActive: false, searchActive: false, addressSearch: false, filtersActive: false, query: '', results: [], addressResults: [] });
  }

  /* ------ Renders ------ */

  renderResult(vehicle, i) {
    return (
      <Link to={{ pathname: `/vehicle/${vehicle.id}`, state: { vehicle } }} key={i}>
        <div className="fm-vehicles-result">
          <p className="fm-vehicles-result-registration">{ vehicle.registration }</p>
          <p className="fm-vehicles-result-qr">{ vehicle.qrCode }</p>
          <div className="fm-vehicles-result-right">
            <p className="fm-vehicles-result-status" style={{ backgroundColor: colors.status[vehicle.status] }}>{ vehicle.status }</p>
            <p className="fm-vehicles-result-imei">{ vehicle.iotCode }</p>
          </div>
        </div>
      </Link>
    );
  }

  renderAddress(place, i) {
    return (
      <div className="fm-vehicles-result" key={i} onClick={() => this.onPlaceClick(place)}>
        <p className="fm-vehicles-result-qr">{ place.description }</p>
      </div>
    );
  }

  renderOption(status, i) {
    const style = { color: '#222222', background: '#F8F7F9' };

    if (this.state.filters.statuses.includes(status)) {
      style.background = colors.status[status];
      style.color = '#FFF';
    }

    return (
      <span
        className="fm-vehicle-finder-filters-status"
        key={i}
        style={style}
        onClick={() => this.toggleStatus(status)}
      >
        { status }
      </span>
    );
  }

  render() {
    const { scannerActive, searchActive, filtersActive, addressSearch, searching, mapVehicles, results, addressResults, query, filters, hubs, regions, zones } = this.state;
    const sourceClass = 'fm-vehicle-finder-source fm-vehicle-finder-source-' + (scannerActive ? 'scanner' : (searchActive ? 'search' : 'map'));
    const searchClass = 'fm-vehicle-finder-search fm-vehicle-finder-search-' + (searchActive ? 'active' : 'inactive');

    return (
      <div className="fm-vehicle-finder">
        <div className={sourceClass}>
          <Map google={window.google} ref={this.map} onBoundsChange={this.handleMapBoundsChange} isGlobal>
            { mapVehicles.map((vehicle, i) => <Marker key={i} position={{ lat: vehicle.latitude, lng: vehicle.longitude }} title={vehicle.registration} icon={vehicleIcon(vehicle.type, vehicle.status, vehicle.batteryPercent)} onClick={(e) => this.onVehicleClick(e, vehicle)} />) }
            { zones.map((zone, i) => <Polygon key={i} paths={ zone.polygon.map((p) => ({ lat: p.latitude, lng: p.longitude })) } strokeWeight={0} fillColor={colors.zone[zone.type]} fillOpacity={0.2} />) }
            { regions.map((region, i) => <Polygon key={i} paths={ region.serviceArea.map((p) => ({ lat: p.latitude, lng: p.longitude })) } strokeColor={colors.zone.serviceArea} strokeWeight={2} fillOpacity={0} clickable={false} />) }
          </Map>
          { scannerActive && <VehicleScanner hubs={hubs} /> }
        </div>
        <div className="fm-vehicle-finder-bar">
          <Input
            placeholder={ addressSearch ? 'Search address' : 'Search vehicles' }
            inputMode={ addressSearch ? 'text' : 'numeric' }
            ref={this.searchInput}
            value={this.state.query}
            onChange={this.handleSearchChange}
            onFocus={this.handleSearchFocus}
            onBlur={this.handleSearchBlur} />
          {
            !searchActive &&
            <div className="fm-vehicle-finder-bar-locate" onClick={this.handleLocate}>
              <Icon icon="location-arrow" />
            </div>
          }
          <div className="fm-vehicle-finder-bar-filter" onClick={this.toggleFilters}>
            <Icon icon={ filtersActive ? 'chevron-down' : (searchActive ? (addressSearch ? 'dot-circle' : 'home') : 'filter') } />
          </div>
          <div className="fm-vehicle-finder-bar-qr" onClick={this.toggleScanner}>
            <Icon icon={ (scannerActive || searchActive) ? 'times' : 'qrcode' } />
          </div>
        </div>
        <LoadingLine hide={!searching} />
        {
          filtersActive && (
            <div className="fm-vehicle-finder-filters">
              <div className="fm-vehicle-finder-filters-statuses">
                { filterOptions.map(this.renderOption) }
              </div>
              <div className="fm-vehicle-finder-filters-selects">
                <Input type="select" value={filters.model || 'ALL'} onChange={this.modelFilter} options={modelOptions} />
                <Input type="select" value={filters.battery || '1.1'} onChange={this.batteryFilter} options={batteryOptions} />
              </div>
            </div>
          )
        }
        <div className={searchClass}>
          { (!searching && !addressSearch && results.length === 0 && query.length > 0) && <p className="fm-vehicle-finder-results-empty">No vehicles found</p> }
          { (!searching && addressSearch && addressResults.length === 0 && query.length > 0) && <p className="fm-vehicle-finder-results-empty">No addresses found</p> }
          { !addressSearch && results.map(this.renderResult) }
          { addressSearch && addressResults.map(this.renderAddress) }
        </div>
      </div>
    );
  }
}

export default VehicleFinder;
