import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

class DocumentVersion extends Component {
  constructor(props) {
    super(props);
    const preloadedVersion = props.location.state ? props.location.state.version : false;
    this.state = {
      version: preloadedVersion || {},
      loading: true,
      editing: false,
      error: false,
    };
    this.loadVersion = this.loadVersion.bind(this);
    this.onDownload = this.onDownload.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeMarkdown = this.onChangeMarkdown.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    if (this.state.template) {
      document.title = `${this.state.version.subtitle} | Flamingo Admin`;
    } else {
      document.title = 'Document Version | Flamingo Admin';
    }
    this.loadVersion();
  }

  loadVersion() {
    return api.get(`/document/version/${this.props.match.params.versionId}`)
      .then((res) => this.setState({ version: { ...this.state.version, ...res.data.data }, loading: false }))
      .catch(this.handleError);
  }

  onDownload() {
    // return api.post(`/document/template/${this.props.match.params.templateId}/generate`, { date: generateDate })
    //   .then((res) => history.push(`/document/${this.props.match.params.templateId}/version/${res.data.data.id}`))
    //   .catch(this.handleError);
  }

  onChangeStatus(e) {
    const generateDate = e.target.value;
    this.setState({ generateDate });
  }

  onChangeMarkdown(e) {
    const generateDate = e.target.value;
    this.setState({ generateDate });
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  render() {
    const { loading, error, editing, version } = this.state;
    const title = version.template ? version.template.title : (version.subtitle || 'Document Version');

    return (
      <div className="fm-document-version">
        <NavigationBar title={title} loading={loading} showBack={true} />
        <div className="fm-document-version-content">
          <div className="fm-document-version-details">
            <div className="fm-document-version-details-row">
              <p className="fm-document-version-details-row-label">Start Date</p>
              <p className="fm-document-version-details-row-value">{ version.startDate }</p>
            </div>
            <div className="fm-document-version-details-row">
              <p className="fm-document-version-details-row-label">End Date</p>
              <p className="fm-document-version-details-row-value">{ version.endDate }</p>
            </div>
            <div className="fm-document-version-details-row">
              <p className="fm-document-version-details-row-label">Created At</p>
              <p className="fm-document-version-details-row-value">{ version.createdAt }</p>
            </div>
          </div>
          <div className="fm-document-version-markdown">
            <textarea className="fm-document-version-markdown-textarea" value={version.markdown}></textarea>
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default DocumentVersion;
