import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import ReportParking from './components/report-parking/';
import ReportParkingStatistics from './components/report-parking-statistics/';
import ReportParkingReview from './components/report-parking-review/';
import ReportPending from './components/report-pending/';
import ReportView from './components/report-view/';
import ReportTrip from './components/report-trip/';
import ReportTripType from './components/report-trip-type/';
import ReportTripDetails from './components/report-trip-details/';
import ReportChange from './components/report-change/';
import ReportInterference from './components/report-interference/';
import ReportTemplate from './components/report-template/';

class Report extends Component {
  render() {
    return (
      <Switch>
        <Route path="/report/interference/vehicle/:vehicleId" component={ReportInterference} />
        <Route path="/report/trip/:tripId/:category/:typeId" component={ReportTripDetails} />
        <Route path="/report/trip/:tripId/:category" component={ReportTripType} />
        <Route path="/report/trip/:tripId" component={ReportTrip} />
        <Route path="/report/parking/review/:regionId?/:month?/:allTrips?/:allUsers?/:withoutParkingDetection?" component={ReportParkingReview} />
        <Route path="/report/parking/statistics" component={ReportParkingStatistics} />
        <Route path="/report/parking" component={ReportParking} />
        <Route path="/report/templates" component={ReportTemplate} />
        <Route path="/report/category/:category" component={ReportPending} />
        <Route path="/report/:reportId/change/:category" component={ReportChange} />
        <Route path="/report/:reportId" component={ReportView} />
        <Route path="/report" component={ReportPending} />
      </Switch>
    );
  }
}

export default Report;
